import { MEDIA_KEY_ENUM } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import DouyinTopBarChart from '@/pages/account/platform-account/report/components/FansReport/charts/TopBarChart';
import DouyinAgePieChart from '@/pages/account/platform-account/report/components/FansReport/charts/AgePieChart';
import DouyinActiveAudienceTimeBarChart from '@/pages/account/platform-account/report/components/FansReport/charts/ActiveAudienceTimeBarChart';

import { getAccountAnalysis } from '@/services/account';
import { useRequest } from 'ahooks';

export const useDouyinAnalysisData = (orgIds) => {
  const { user } = useAppContext();
  return useRequest(() => getAccountAnalysis(undefined, user?.bid, MEDIA_KEY_ENUM.DOUYIN, orgIds), {
    refreshDeps: [user?.bid, orgIds],
  });
};

export { DouyinTopBarChart, DouyinAgePieChart, DouyinActiveAudienceTimeBarChart };
